<template>
  <!-- 付款合同列表 -->
  <div
    class="bg-white px-3 mt-5"
    style="margin: -20px; margin-top: 1rem; margin-bottom: 0 !important"
  >
    <div class="d-flex align-items-center">
      <!-- 左边 -->
      <router-link :to="{ name: 'contract_fcontract_create' }">
        <el-button type="success" size="mini">新增合同</el-button>
      </router-link>
      <!-- 右边 -->
      <div class="ml-auto d-flex align-items-center" v-show="!superSearch">
        <el-button type="info" size="mini" class="mr-1">搜索</el-button>
        <select-cbfl
          @select-change="selectChange"
          :checks="true"
          selectw="500px"
        ></select-cbfl>
        <!--
        <el-button class="ml-5" size="mini" @click="superSearch = true">高级搜索</el-button>
     -->
      </div>
    </div>
    <!-- 高级搜索块 -->
    <el-card class="box-card my-2" v-show="superSearch">
      <template #header>
        <div style="margin: -8px">
          <span>高级搜索</span>
          <el-button
            class="button"
            type="text"
            style="float: right; padding: 3px 0"
            @click="superSearch = false"
            >收起
          </el-button>
        </div>
      </template>
      <el-form inline ref="form" :model="form" label-width="80px">
        <el-form-item label="合同名称" class="mb-0">
          <el-input v-model="form.name" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="供应商名称" class="mb-0">
          <el-input v-model="form.name" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="成本类别" class="mb-0">
          <el-input v-model="form.name" size="mini"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="info" size="mini">搜索</el-button>
          <el-button size="mini">清空筛选条件</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <el-table
      border
      class="mt-3"
      :data="tableData"
      :show-summary="true"
      :summary-method="summaryMethod"
      style="width: 100%"
    >
      <el-table-column label="操作" width="240" align="center">
        <template #default="scope">
          <el-button-group>
            <el-button
              size="small"
              plain
              @click="handleEdit(scope.$index, scope.row)"
              >修改</el-button
            >
            <el-button size="small" plain @click="pdfview(scope.row.id)"
              >查看</el-button
            >
            <el-button
              size="small"
              plain
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </el-button-group>
        </template>
      </el-table-column>
      <el-table-column
        prop="contract_signt_time"
        label="日期"
        width="100"
        align="center"
      />
      <el-table-column
        prop="supplier_name"
        label="供应商名称"
        width="240"
        align="center"
      />
      <el-table-column
        prop="contract_name"
        label="合同名称"
        width="300"
        align="center"
      />
      <el-table-column
        prop="contract_amount"
        label="合同金额"
        width="180"
        align="center"
        :formatter="toThousands"
      />
      <el-table-column
        prop="contract_num"
        label="合同编号"
        width="180"
        align="center"
      />
      <el-table-column
        prop="category_id"
        label="成本类别"
        width="180"
        align="center"
      />
      <el-table-column
        prop="contract_condition"
        label="付款条件"
        width="180"
        align="center"
      />
      <el-table-column
        prop="contract_main"
        label="主要条款"
        width="180"
        align="center"
      />
      <el-table-column
        prop="contract_text"
        label="备注"
        width="180"
        align="center"
      />
      <el-table-column label="发票种类" width="180" align="center">
        <template #default="scope">
          <span v-if="scope.row.tax_class == 1">普票</span>
          <span v-if="scope.row.tax_class == 2">专票</span>
          <span v-if="scope.row.tax_class == 3">无票</span>
        </template>
      </el-table-column>
      <el-table-column label="发票税率" width="180" align="center">
        <template #default="scope">
          <span>{{ scope.row.tax_rate + "%" }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 60px"></div>
    <!-- 占位底部60PX -->
    <el-footer
      class="border-top d-flex align-items-center px-0 position-fixed bg-white"
      style="bottom: 0; left: 150px; right: 0; z-index: 100"
    >
      <el-config-provider :locale="locale">
        <el-pagination
          v-model:currentPage="currentPage"
          v-model:page-size="pageSize"
          :page-sizes="[5, 10, 20, 100, 300]"
          layout="sizes, prev, pager, next,total,jumper"
          :total="lotalrows"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-config-provider>
    </el-footer>
  </div>
  <!-- 查看PDF文件List -->
  <el-dialog v-model="pdfurlVisible" width="100%" fullscreen>
    <!--fileclass分类ID     0-项目图片  1-付款合同  2-付款申请   3-发票   
       4-银行回单  5-收款合同  6-收款凭证 -->
    <pdf-view fileclass="1" :prdclassid="prdclassid"></pdf-view>
  </el-dialog>
</template>

<script>
import axios from "axios";
import zhCnelementpage from "element-plus/lib/locale/lang/zh-cn";
export default {
  data() {
    return {
      //分页数据
      pageSize: 10, //每页数据 默认10条
      currentPage: 1, //当前页码  默认第1页
      lotalrows: 0, //总行数
      locale: zhCnelementpage, //中文分页

      prdclassid: "", //查看附件记录的ID
      pdfurlVisible: false, //查看附件列表开关

      superSearch: false,
      tableData: [],
      //按成本分类ID搜索
      category_id: "",
      //合计总金额
      totalamount: 0,
      //高级筛选条件
      form: {
        name: "",
      },
    };
  },
  created() {
    this.loadfcontractinfo(this.pageSize, this.currentPage);
  },
  methods: {
    //根据分类筛选 更改成本分类选项
    selectChange(itme) {
      //计算生成成本ID（/一级分类id/二级分类id/.../)
      let temp = "";
      for (let i = itme.length - 1; i >= 0; i--) {
        temp = itme[i] + "/" + temp;
      }
      temp = "/" + temp;
      this.category_id = temp;
      console.log("成本分类选项", this.category_id);
      this.loadfcontractinfo(this.pageSize, this.currentPage);
    },
    //选择页码选项
    handleCurrentChange(val) {
      console.log("选择页码选项", val);
      this.currentPage = val;
      this.loadfcontractinfo(this.pageSize, this.currentPage);
    },
    //选择每页条数
    handleSizeChange(val) {
      console.log("选择每页条数", val);
      this.pageSize = val;
      this.loadfcontractinfo(this.pageSize, this.currentPage);
    },

    handleEdit(index, row) {
      console.log(index, row.id);
      //打开修改页面
      window.sessionStorage.setItem("fcontractrowid", row.id);
      this.$router.push({ name: "contract_fcontract_update" });
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
    loadfcontractinfo(rows, pags) {
      axios
        .get("admin/FcontractInfo", {
          params: {
            project_id: window.sessionStorage.getItem("project_ids"),
            page: pags,
            list_rows: rows,
            category_id: this.category_id,
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log("******99", response.data.data, "99******");
          this.currentPage = response.data.current_page; //当前页码  默认第1页
          this.lotalrows = response.data.total; //总行数
          this.tableData = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //查看pdf清单列表
    pdfview(vals) {
      console.log("*********qwqwqwqw", vals);
      this.prdclassid = vals;
      this.pdfurlVisible = true;
    },
    //格式化数字
    toThousands(cellValue) {
      return cellValue.contract_amount.toLocaleString("zh", {
        style: "currency",
        currency: "CNY",
      });
    },
    //合计行
    summaryMethod(param) {
      let arr = [];
      let totalsum = 0;
      param.data.forEach((element) => {
        totalsum += element.contract_amount;
      });

      arr[0] = "合计";
      arr[4] = totalsum.toLocaleString("zh", {
        style: "currency",
        currency: "CNY",
      });
      return arr;
    },
  },
};
</script>

<style scoped>
.el-table__footer-wrapper .cell {
  text-align: right;
  font-size: 15px;
  font-weight: bold;
}
</style>
